<template>
  <div class="index">
    <van-nav-bar
        left-text="关键词提醒"
        left-arrow
    />
    <div class="content" style="margin-bottom: 60px">
      <van-tabs v-model="active" color="#1890ff" title-active-color="#1890ff"	>
        <van-tab title="今天">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div class="list">
              <img style="margin-top: -12px;" src="../../assets/images/code.png">
              <div style="    position: relative;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;">
                <div style="color: #444;
    font-size: 15px;">春竹</div>
                <div style="color: #1890ff;
    font-size: 12px;">关键词：红包</div>
                <div style="color: #9b9b9b;font-size:12px">流失时间：2022-01-17 16:42</div>
                <span style="font-size: 12px">查看客戶</span>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="全部">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div class="list">
              <img style="margin-top: -5px;" src="../../assets/images/code.png">
              <div style="    position: relative;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;">
                <div style="color: #444;
    font-size: 15px;">春竹</div>
                <div style="color: #9b9b9b;">流失时间：2022-01-17 16:42</div>
                <span>查看客戶</span>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finished:true
    }
  },
  watch: {},
  methods: {
  }
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

/deep/ .tabs .van-cell__title {
  width: 56px !important;
  flex: none !important;
  white-space: nowrap;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .header-tool {
    padding: 0 18.2347px;
    height: 40px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: #fff;
    .clickable{
      font-size: 12px;
    }
    .title {
      font-size: 14px;
      margin: 0 !important;
    }

    .select-all {
      font-size: 12px;
      color: #1890ff;
    }
  }

  .content {
    background: #fff;
    min-height: 92%;
    img {
      width: 22px;
      height: 22px;
    }
    .list{
      background: #fff;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img{
        width: 48px;
        height: 48px;
      }
      div{
        flex: 1;
        margin-left: 5px;
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        justify-content: space-between;
        span{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #ebf7ff;
          border: 1px solid #daedff;
          color: #0091ff;
          padding: 1px 2px;
        }
      }
    }
  }
}
</style>
